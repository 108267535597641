<div class="blog">
  <div class="all-area">
    <app-header-one></app-header-one>
    <app-breadcrumb-parkingmanagementsystem></app-breadcrumb-parkingmanagementsystem>
    <section id="blog" class="section blog-area ptb_100">
      <div class="container" style="margin-bottom: 4rem">
        <div class="row align-items-center">
          <div >
            <div class="about-text">
              <div class="text-justify">
                <p class="my-3">
                  We provide Parking Guidance and Information Systems integrated with Automatic
                  Gate Barriers, as well as a comprehensive range of Rising Arm Barriers, Turnstiles, and
                  Parking Equipment. Our offerings adhere to international safety standards and are
                  meticulously designed to ensure maximum reliability, operational efficiency, and
                  simplified maintenance.                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</div>