import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IFeedBack } from '../model/banners.model';
import { SERVER_API_URL } from '../util/common-util';

type EntityResponseType = HttpResponse<IFeedBack>;


@Injectable({ providedIn: 'root' })
export class FeedbackService {
  public resourceUrl = SERVER_API_URL;
  private headers = new HttpHeaders({'content-type': 'application/json'})
 

  constructor(private httpClient: HttpClient) {}

  // create(feedback: IFeedBack): Observable<EntityResponseType> {
  //   return this.http.post<IFeedBack>(this.resourceUrl, feedback, { observe: 'response' });
  // }
  sendEmail(contact: IFeedBack): Observable<any>{
    return this.httpClient.post<any>(
      this.resourceUrl,
    {//Post body
      name: contact.name,
      email: contact.email,
      message: contact.message
    },
    {
      headers: this.headers
    }
    )
  }
  private handleError<T>(operation = 'operation', result?: T){
    return(error: any): Observable<T> =>{
      //We can stream this log to an platform like CloudWatch
      console.error(error);
  
      //Let our app keep running
      return of(result as T);
    }
  }


}
