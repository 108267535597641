<div class="blog">
  <div class="all-area">
    <app-header-one></app-header-one>
    <app-breadcrumb-solar></app-breadcrumb-solar>
    <section id="blog" class="section blog-area ptb_100">
      <div class="container" style="margin-bottom: 4rem">
        <div class="row align-items-center">
          <div >
            <div class="about-text">
              <div class="text-justify">
                <p class="my-3">
                  OBIX Renewables (Pvt) Ltd specializes in the comprehensive provision of
                  services encompassing the design, engineering, supply, installation, and
                  commissioning of ground based and rooftop photovoltaic power plants.
                  Committed to delivering enduring value, we provide extensive training for our
                  clients' teams and offer maintenance contracts to ensure seamless, trouble-free
                  operation for years to come. Collaborating with meticulously chosen
                  manufacturers of Solar PV components, we guarantee the long-term
                  performance and sustainability of our systems, ensuring optimal functionality
                  for decades ahead. We deliver all 3 types of solar system with the needs and
                  expectations of our clients.
                </p>
                <ul class="disk-list">
                  <li>Off Grid Solar System</li>
                  <li>On Grid Solar System</li>
                  <li>Hybrid Solar System</li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</div>