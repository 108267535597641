<div class="blog">
  <div class="all-area">
    <app-header-one></app-header-one>
    <app-breadcrumb-accesscontrolsystem></app-breadcrumb-accesscontrolsystem>
    <section id="blog" class="section blog-area ptb_100">
      <div class="container" style="margin-bottom: 4rem">
        <div class="row align-items-center">
          <div >
            <div class="about-text">
              <div class="text-justify">
                <p class="my-3">
                  We specialize in designing and implementing Access Control Systems, enabling the
                  management of entry permissions to secure areas. Our solutions encompass both
                  physical and logical access control systems. Some of the access control solutions we
                  offer include,
                  <br>
                  Door Access Control
                  Biometric Card based solutions.
                  Time Attendance System
                  Automatic Doors Barriers
                  Elevator Control System
                  Smart Automation with other ELV devices                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</div>