<section class="d-none d-md-block">
  <h2 class="text-center mb-3">Solutions</h2>
  <div class="container mt-5">
      <div class="custom-container">
        <div class="custom-content d-flex flex-column">
          <div >
            <h2 class="card-title text-white mt-5">Solar Solutions</h2>
            <p class="card-text text-center text-white">Solar solutions to help you manage electricity costs for your
              home at the best price from the finest solar company in Sri Lanka.</p>
          </div>
        </div>

    </div>
    <!-- <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="card mb-3">
            <div class="card-body">
              <h2 class="card-title">Solar Solutions</h2>
              <img src="/assets/SolarPowerSoloutions.jpg" alt="">
              <p class="card-text">Solar solutions to help you manage electricity costs for your home at the best price
                from the finest solar company in Sri Lanka.</p>
              <a href="https://bamgreen.lk/projects/" class="btn btn-light">Learn More</a>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="card mb-3">
            <div class="card-body">
              <h2 class="card-title">Other Solutions</h2>
              <p class="card-text">Solar solutions to help you manage electricity costs for your home at the best price
                from the finest solar company in Sri Lanka.</p>
              <a href="https://bamgreen.lk/projects/" class="btn btn-light">Learn More</a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</section>