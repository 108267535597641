<div class="blog">
  <div class="all-area">
    <app-header-one></app-header-one>
    <app-breadcrumb-solar></app-breadcrumb-solar>
    <section id="blog" class="section blog-area ptb_100">
      <div class="container" style="margin-bottom: 4rem">
        <div class="row align-items-center">
          <div >
            <div class="about-text">
              <div class="text-justify">
                <p class="my-3">
                  OBIX provides an integrated and comprehensive surveillance solution portfolio,
covering all aspects of surveillance requirements including cabling/wiring, camera
procurement and installation, recording solutions, storage, and video walls for
centralized monitoring via control rooms. Our solutions are adaptable and versatile,
catering to diverse environments and purposes such as transport safety, retail
settings, educational institutions, and industrial applications necessitating rugged
camera types. From analog to IP-based systems, and utilizing point-to-point, mesh
wireless, or wired network configurations, we deliver high-performance security
systems at competitive prices</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</div>