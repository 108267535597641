<section id="blog" class="section blog-area ptb_100">
    <div class="container reveal">
        <div class="row justify-content-center">
            <!-- Section Heading -->
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">We specialize in the Design, Supply & Installation of</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum
                        obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum
                        obcaecati.</p>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12 col-md-4 col-lg-4">
                <div class="work-wrapper d-none d-md-block">
         
                        <li>
                            <img src="assets/img/15.png" alt="">
                        </li>
                </div>
              
            </div>
            <div class="col-12 col-md-8 col-lg-4">

                <ul>
                    <li>Coffee</li>
                    <li>Tea</li>
                    <li>Milk</li>
                  </ul>
             
            </div>

        </div> -->
        <div class="future-list">
            <div>


                <img src="assets/img/15.png" alt="">

            </div>
            <div>

                <ul class="text-center mt-2">
                    <li> IPTV System</li>
                    <li> MATV/SMATV/CATV System</li>
                    <li>Milk</li>
                </ul>
            </div>

        </div>
    </div>
</section>