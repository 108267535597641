<div class="blog">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>
        <app-breadcrumb-blog-ourteam-column></app-breadcrumb-blog-ourteam-column>
        <section id="blog" class="section blog-area ptb_100">
         <div class="h4-heading">
            <h4>Take a look into our amazing team.</h4>
         </div>
            <div class="container">
                <div >
                    <div class="gridContainer">
                        <div class="row spaced-cols content-center-sm" data-type="row">
                            <div class="col-xs-12 col-sm-6 col-md-3 col-padding">
                                <div class="row ">
                                    <div data-type="column" class="col-xs-12 col-sm-12 "> <img class="round face"
                                        src="https://solarpower.lk/wp-content/uploads/2022/06/cropped-user.png"
                                        title="cropped-user.png" alt="">
                                    <h4 class="font-400">Harendra K. Balapatabendi</h4>
                                    <p >Chairman / Managing Director</p>
                                </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3 col-padding">
                                <div class="row ">
                                    <div data-type="column" class="col-xs-12 col-sm-12"> <img class="round face"
                                            src="https://solarpower.lk/wp-content/uploads/2022/06/cropped-user.png"
                                            title="cropped-user.png" alt="">
                                        <h4 class="font-500">Nimna Bandulage</h4>
                                        <p >General Manager</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3 col-padding">
                                <div class="row ">
                                    <div data-type="column" class="col-xs-12 col-sm-12"> <img class="round face"
                                            src="https://solarpower.lk/wp-content/uploads/2022/06/cropped-user.png"
                                            title="cropped-user.png" alt="">
                                        <h4 class="font-500">Seshan Sandeepa</h4>
                                        <p>CTO – Engineer</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-3 col-padding">
                                <div class="row ">
                                    <div data-type="column" class="col-xs-12 col-sm-12"> <img class="round face"
                                            src="https://solarpower.lk/wp-content/uploads/2022/06/cropped-user.png"
                                            title="cropped-user.png" alt="">
                                        <h4 class="font-500">Ranga</h4>
                                        <p >Sales Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </section>

        <app-footer></app-footer>
    </div>
</div>