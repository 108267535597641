import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ourvision',
  templateUrl: './ourvision.component.html',
  styleUrls: ['./ourvision.component.css']
})
export class OurVisionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
