<header class="section header-area">
    <div id="appo-header" class="main-header-area nav-white">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <!-- Logo -->
                <a class="navbar-brand" href="#">
                    <img class="logo" src="assets/img/logo_white.png" alt="">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- Appo Menu -->
                <div class="collapse navbar-collapse" id="appo-menu">
                    <!-- Header Items -->
                    <ul class="navbar-nav header-items ml-auto">
                        <li class="nav-item">
                            <a class="nav-link scroll" href="#home">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" href="#features">Features</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link scroll" href="#pricing">Pricing</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a class="nav-link scroll" href="#team">Team</a>
                        </li> -->
                        <li class="nav-item"><a class="nav-link scroll" routerLink="/Projects">Projects</a></li>
              
                        <li class="nav-item">
                            <a class="nav-link scroll" href="#contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>