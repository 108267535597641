import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-solar',
  templateUrl: './breadcrumb-solar.component.html',
  styleUrls: ['./breadcrumb-solar.component.css']
})
export class BreadcrumbSolarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
