import { animate, query, stagger, style, transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  })
export class AboutComponent implements OnInit {
photos: any;

  constructor() { }

  ngOnInit(): void {

 

}
}