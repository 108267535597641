import { AfterViewInit, Component, OnInit } from '@angular/core';
import * as feather from 'feather-icons';

@Component({
  selector: 'app-blog-services',
  templateUrl: './blog-services.component.html',
  styleUrls: ['./blog-services.component.css']
})
export class BlogServicesComponent implements OnInit,AfterViewInit {

  constructor() { }

  ngOnInit(): void {
   
    window.addEventListener("scroll", this.reveal);
    this.reveal();
  }
  reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
    
  
}
ngAfterViewInit() {
  feather.replace();
}

}
