<section id="contact" class="contact-area bg-gray ptb_100 reveal">
        <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    
                    <h2 class="text-capitalize">Stay Tuned</h2>
                    <p class="d-none d-sm-block mt-4">please let me know your feedback.</p>
                    <!-- <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- Contact Box -->
                <div class="contact-box text-center">
                    <!-- Contact Form -->
                    <form id="cotter-form-container" name="editForm" role="form" novalidate (ngSubmit)="save()" [formGroup]="editForm">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="name" placeholder="Name" formControlName="name" required="required" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" name="email" formControlName="email" placeholder="email" required="required" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <input type="text" class="form-control" name="subject" placeholder="Subject" formControlName="subject" required="required">
                                </div> -->
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <textarea class="form-control" name="message" style="height: 140px;" placeholder="Message" formControlName="message" required="required"  [ngClass]="{ 'is-invalid': submitted && f.message.errors }"></textarea>
                                    <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                        <div *ngIf="f.message.errors.required">Message is required</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-bordered mt-3 mt-sm-4 mb-4" type="submit">Send Message</button>
                            </div>
                        </div>
                    </form>
                    <!-- <p class="form-message"></p> -->
                </div>
            </div>
        </div>
        <div *ngIf="isAlert==true" class="alert simple-alert">
            <h6 style="margin-left: 4px;">Your submission has been sent.</h6>
            <a class="close" onclick="this.parentElement.style.display='none';">&times;</a>
          </div>
    </div>
</section>