<div class="blog">
  <div class="all-area">
    <app-header-one></app-header-one>
    <app-breadcrumb-about></app-breadcrumb-about>
    <section id="blog" class="section blog-area ptb_100">
      <div class="container" style="margin-bottom: 4rem">
        <div class="row align-items-center">
          <div class="col-12 col-lg-6">
            <div class="about-thumb text-center d-none d-lg-block">
              <img src="/assets/hemal.png" alt="hemal" width="auto" height="450px" />
              <h4 >Hemal Chathuranga</h4>
              <p style="color: #184A92; font-weight: 100;"><b>Founder / CEO - OBIX & OBIX RENEWABLES (PVT) LTD</b></p>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="about-text">
              <div style="display: flex;align-items: flex-start;">
                <div style="width: 16px; height: 222px; background: #184A92; margin-right: 16px; ">hk</div>
                <div class="mr-2">
                  <p class=" mr-2">
                    On behalf of OBIX (Pvt) Ltd and OBIX Renewables (Pvt) Ltd, I extend a warm welcome to each of you.
                    As the CEO and Founder,
                    I am immensely proud to lead these innovative ventures in the realms of ELV and solar power
                    solutions. At OBIX, we are
                    committed to delivering excellence in every aspect of our services, providing cutting edge
                    technology and sustainable
                    solutions tailored to meet your specific needs. Our dedicated team is driven by a passion for
                    customer satisfaction and a
                    vision for a greener, more connected future. Thank you for entrusting us with your business, and we
                    look forward to
                    building lasting partnerships that drive mutual success.
                  </p>
                </div>
                
              </div>
              <div>
                <br>
                 <div class="custom-heading1">“</div>
              <p class="custom-heading2">Customers aren't just transactions; they're the heartbeat of our business. At OBIX, we're dedicated to exceeding their expectations, because their satisfaction is the true measure of our success.</p>
              <div class="custom-heading1 d-flex justify-content-end">”</div>
              </div>
             
            </div>

          </div>

        </div>
      </div>
     
    </section>
    <app-ourmission></app-ourmission>
    <app-blog-keybenifits></app-blog-keybenifits>
    <app-footer></app-footer>
  </div>
</div>