<div class="blog">
  <div class="all-area">
    <app-header-one></app-header-one>
    <app-breadcrumb-design-consulting></app-breadcrumb-design-consulting>
    <section id="blog" class="section blog-area ptb_100">
      <div class="container" style="margin-bottom: 4rem">
        <div class="row align-items-center">
          <div class="col-12 col-lg-6">
            <div class="about-text">
              <div class="text-justify">
                <p class="my-3">
                  We offer a comprehensive spectrum of consulting and tailored design services,
                  encompassing network architecture, security solutions, IT hardware solutions,
                  automation, procurement, and tendering planning, as well as design, management,
                  and integration advisory. Our aim is to furnish secure, resilient, and scalable solutions
                  that effectively address your IT security requirements.
                </p>
              </div>
            </div>
          </div>
          <!-- <div >
            <div class="about-thumb text-center d-none d-lg-block">
              <img src="/assets/hemal.png" alt="hemal" width="400" height="auto" />
              </div>
          </div> -->
        </div>
      </div>
    </section>
    <app-footer></app-footer>
  </div>
</div>