import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-about',
  templateUrl: './blog-about.component.html',
  styleUrls: ['./blog-about.component.css']
})
export class BlogAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   
    window.addEventListener("scroll", this.reveal);
    this.reveal();
  }
  reveal() {
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
    
  
}

}
