<div class="blog">
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>

        <app-breadcrumb-services></app-breadcrumb-services>
        <section class="main-section" id="service"><!--main-section-start-->
            <div class="container text-center">
                <h4>We offer dynamic range of innovative and cost effective solutions to various field in annual maintenance contract.  </h4>
               
                    <div class="row ">
                        <span class="border">
                        <div class="col">
                            <h3>AMC</h3>
                        </div>
                    </span>
                    <span class="border">
                        <div class="col">
                            <h3>Onsite Support</h3>
                        </div>
                    </span>
                    </div>
                    <div class="row ">
                        <span class="border">

                        <div class="col">
                            <h3>remote assistance</h3>
                        </div>
                    </span>
                        <span class="border">
                        <div class="col">
                            <h3>ELV integration</h3>
                        </div>
                    </span>

                    </div>
                    <!-- <div class="col-lg-4 col-sm-6 wow fadeInLeft delay-05s">
                        <div class="service-list">
                            <div class="service-list-col1">
                                <i class="fa fa-user"></i>
                            </div>
                            <div class="service-list-col2">
                                <h3>AMC</h3>
                                 </div>
                        </div>
                        <div class="service-list">
                            <div class="service-list-col1">
                                <i class="fa fa-user"></i>
                            </div>
                            <div class="service-list-col2">
                                <h3>Onsite Support</h3>
                              </div>
                        </div>
                        <div class="service-list">
                            <div class="service-list-col1">
                                <i class="fa fa-user"></i>

                            </div>
                            <div class="service-list-col2">
                                <h3>remote assistance</h3>
                                 </div>
                        </div>
                        <div class="service-list">
                            <div class="service-list-col1">
                                <i class="fa fa-user"></i>

                            </div>
                            <div class="service-list-col2">
                                <h3>ELV integration</h3>
                             </div>
                        </div>
                    </div>
                    <figure class="col-lg-8 col-sm-6  text-right wow fadeInUp delay-02s">
                        <img src="" alt="">
                    </figure> -->
                
            </div>
        </section><!--main-section-end-->

        <app-footer></app-footer>
    </div>
</div>
