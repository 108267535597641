<section id="screenshots" class="section screenshots-area ptb_100">
    <div class="container">
        <h2 class="text-center mb-3">Why CHoose US?</h2>
        <p class="pt-4 pb-4"> We deliver beyond mere security by leveraging
            our highly skilled staff to provide cost-effective
            solutions enriched with value-added services.
            These encompass consultancy, seamless
            system integration, and industry-leading after
            sales support. Our clients can rest assured of
            optimal solutions tailored to their specific
            needs and objectives. </p>
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <div class="divsec">
                        <i class="bi bi-shop-window fa-2xl item"></i>
                        <h5 style="margin-top: 14px; text-align: center;">One Stop Shop Approach</h5>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <div class="divsec">
                        <i class="bi bi-currency-dollar fa-2xl item"></i>
                        <h5 style="margin-top: 14px; text-align: center;">Honest Transparent Pricing</h5>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <div class="divsec">
                        <i class="bi bi-person-fill fa-2xl item"></i>
                        <h5 style="margin-top: 14px; text-align: center;">Professional Friendly Staff</h5>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <div class="divsec">
                        <i class="bi bi-cpu-fill fa-2xl item"></i>
                        <h5 style="margin-top: 14px; text-align: center;">Training Technology</h5>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <div class="divsec">
                        <i class="bi bi-globe-asia-australia fa-2xl item"></i>
                        <h5 style="margin-top: 14px; text-align: center;">Sustainable Considerate</h5>
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <div class="divsec">
                        <i class="bi bi-geo-alt-fill fa-2xl item"></i>
                        <h5 style="margin-top: 14px; text-align: center;">Proven Track Record</h5>
                    </div>
                </div>
                <!-- Repeat as needed -->
            </div>
        </div>




    </div>



</section>