<section class="section download-area ptb_100 reveal"style="background: #5a5a5a;">
    <!-- Shapes Container -->
    <div class="container ">
        <div class="row justify-content-center">
            <div class="container visionk">
                <div class="row align-items-center">
                  <div class="col-12 col-lg-6">
                    <div class="about-text">
                      <div class="text-justify">
                        <h2 class="underline text-white">OUR MISSION</h2>
                        <br />
                        <p class="my-3 pr-5 text-white" style="padding-right: 6rem">
                          To provide our clients with Efficient, Cost–Effective and Quality services and solutions by continuous
                          review and
                          improvement in our Services and Facilities considering the exact needs and expectations of our
                          clients.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <div class="text-justify">
                      <h2 class="underline text-white">OUR VISION</h2>
                      <br />
                      <p class="my-3 pr-5 text-white">
                        To exceed the consumer's expectations by providing innovative, systematic, and goal orientated them to
                        operate at fullest
                        potential with quality services and cost effective solutions to enable them to operate at fullest
                        potential with competitive
                        edge in modern industry.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
        </div>
    </div>
</section>