<div class="blog">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>
        <app-breadcrumb-blog-ourteam-column></app-breadcrumb-blog-ourteam-column>
        <section class="reveal mb-4"><!--main-section alabaster-start-->
            <div class="container">
                <div class="">
                    <P class="mb-4">We give you more than security with our highly skilled staff to deliver
                        cost effective with value sadded services including consultancy, system integration and
                        market leading after sale services our client are assured for optimal solution.</P>

                </div>

                <!-- <div class="d-flex align-items-center justify-content-between image-wrapper">
                    <div class="row">
                        <div class="col-lg-12 col-sm-6 wow fadeInLeft delay-05s">
                            <div class="featured-box">
                                <div class="featured-box-col1 wow fadeInRight delay-02s">
                                    <i class="bi bi-person-fill fa-2xl	"></i>
                            </div>
                        </div>
                                <h4>Professional Friendly Staff</h4>
                            </div>
                       
                        
                        <div class="col-lg-12 col-sm-6 wow fadeInLeft delay-05s">
                            <div class="featured-box">
                                <div class="featured-box-col1 wow fadeInRight delay-04s">
                                    <i class="bi bi-cpu-fill"></i>
                                </div>
                                <div class="featured-box-col2 wow fadeInRight delay-04s">
                                    <h4>Training Technology</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-6 wow fadeInLeft delay-05s">
                            <div class="featured-box">
                                <div class="featured-box-col1 wow fadeInRight delay-06s">
                                    <i class="bi bi-shop-window"></i>
                                </div>
                                <div class="featured-box-col2 wow fadeInRight delay-06s">
                                    <h4>One Stop Shop Approach</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-sm-6 wow fadeInLeft delay-05s">
                            <div class="featured-box">
                                <div class="featured-box-col1 wow fadeInRight delay-06s">
                                    <i class="bi bi-globe-asia-australia"></i>
                                </div>
                                <div class="featured-box-col2 wow fadeInRight delay-06s">
                                    <h4>Sustainable Considerate</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-6 wow fadeInLeft delay-05s">
                            <div class="featured-box">
                                <div class="featured-box-col1 wow fadeInRight delay-06s">
                                    <i class="bi bi-geo-alt-fill"></i>
                                </div>
                                <div class="featured-box-col2 wow fadeInRight delay-06s">
                                    <h4>Proven Track Record</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-sm-6 wow fadeInLeft delay-05s">
                            <div class="featured-box">
                                <div class="featured-box-col1 wow fadeInRight delay-06s">
                                    <i class="bi bi-currency-dollar"></i>
                                </div>
                                <div class="featured-box-col2 wow fadeInRight delay-06s">
                                    <h4>Honest Transparent Pricing</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  -->

                <div>
                    <div class="row spaced-cols center-sm content-center-sm" data-type="row">
                        <div class="col col-sm-6 col-md-3 space-bottom ">
                                <div class="hi">
                                    <span class="item1 border border-primary rounded-circle " >
                                        <i class="bi bi-person-fill"></i>
                                    </span>
                                    
                                </div>
                            <h4 >Professional Friendly Staff</h4>
                        </div>
                        <div class="col col-sm-6 col-md-3 space-bottom">
                            <div class="hi">
                                <span class="item1 border border-primary rounded-circle " >
                                <i class="bi bi-cpu-fill fa-2xl	"></i>
                            </span>
                        </div>

                            <h4>Training Technology</h4>
                        </div>
                        <div class="col col-sm-6 col-md-3 space-bottom">
                            <div class="hi">
                                <span class="item1 border border-primary rounded-circle " >
                            <i class="bi bi-shop-window fa-2xl	"></i>
                            </span>
                        </div>

                            <h4>One Stop Shop Approach</h4>
                        </div>
                    </div>
                </div>
                <!-- dfgdfgd? -->
                <div style="padding-bottom: 52px;" class="row spaced-cols center-sm content-center-sm" data-type="row">
                    <div class="col col-sm-6 col-md-3 space-bottom">
                        <div class="hi">
                            <span class="item1 border border-primary rounded-circle " >
                        <i class="bi bi-globe-asia-australia fa-2xl	"></i>
                        </span>
                    </div>

                        <h4>Sustainable Considerate</h4>
                    </div>
                    <div class="col col-sm-6 col-md-3 space-bottom">
                        <div class="hi">
                            <span class="item1 border border-primary rounded-circle " >
                            <i class="bi bi-geo-alt-fill fa-2xl	"></i>
                        </span>
                    </div>

                        <h4>Proven Track Record</h4>
                    </div>
                    <div class="col col-sm-6 col-md-3 space-bottom">
                        <div class="hi">
                            <span class="item1 border border-primary rounded-circle " >
                            <i class="bi bi-currency-dollar fa-2xl	"></i>
                        </span>
                        </div>
                        <h4>Honest Transparent Pricing</h4>
                    </div>
                </div>
            </div>

        </section><!--main-section alabaster-end-->

        <app-footer></app-footer>
    </div>
</div>