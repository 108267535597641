<section class="section download-area ptb_100 reveal">
    <!-- Shapes Container -->
    <div class="shapes-container d-none d-sm-block">
        <div class="shape-2"></div>
        <div class="shape-3"></div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Download Text -->
                <div class="download-text text-center">
                    <h2>Our Vision</h2>
                    <p class="my-3" style="font-size: 15px;">To exceed the consumer's expectations by providing innovative, systematic and goal-orientated them to operate at fullest potential with quality services and cost effective solutions to enable them to operate at fullest potential with competitive edge in modern industry.
                    </p>
                    <!-- <p class="d-none d-sm-block my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique dolor ut iusto vitae autem neque eum ipsam, impedit asperiores vel cumque laborum dicta repellendus inventore voluptatibus et explicabo nobis unde.</p> -->
                    <!-- Store Buttons -->
                </div>
            </div>
        </div>
    </div>
</section>