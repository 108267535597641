<section id="features" class="section features-area ptb_100 reveal">
    <div class="shapes-container">
        <div class="shape bg-shape"></div>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 col-lg-6">
                <div class="features-text">
                    <!-- Headings -->
                    <div class="headings d-flex align-items-center mb-4">
                        <span class="text-uppercase d-none d-sm-block">Features</span>
                        <h2 class="text-capitalize">Built In Powerful Features you Can't Miss</h2>
                    </div>
                    <p class="my-3">Today our products and services guarantees with International standards as verified by our 
                        esteemed customers for the past years. Tech Solution Network is fully proficient in Manufacturing, 
                        Supplying, Installation and Maintenance of domestic TVRO Systems up to commercial grade Satellite 
                        Receiving earth stations, Telecommunication projects. At present, Tech Solution Network is geared 
                        with well-trained Technical Staff, Electronics Division, Service & Installation, Planning Division Team 
                        and a sales outlet situated in Boralesgamuwa. Company also provides facilities such as consultation, 
                        Designing/installation/Planning of telecommunication & MATV systems jointly with Europe’s leading 
                        products.</p>
                    <!-- Counter List -->
                  
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-5 offset-lg-1">
                <!-- Featured Items -->
                <div class="featured-items">
                    <ul>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/icon_1.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Reduced Electric Bill</h3>
                                    <p> solar system has generated, your energy bills will drop.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/icon_2.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Diverse Applications</h3>
                                    <p>Solar energy can be used for diverse purposes. You can generate electricity or hea.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/icon_3.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Low Maintenance Costs</h3>
                                    <p>Solar energy systems generally don’t require a lot of maintenance.</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <!-- Single Features Item -->
                            <div class="single-features media p-3">
                                <!-- Features Title -->
                                <div class="features-title mr-3">
                                    <img src="assets/img/icon_4.svg" alt="">
                                </div>
                                <!-- Features Text -->
                                <div class="features-text media-body">
                                    <h3>Technology Development</h3>
                                    <p>Technology in the solar power industry is constantly advancing and improvements will intensify in the future.</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>