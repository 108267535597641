import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solar-solutions',
  templateUrl: './solar-solutions.component.html',
  styleUrls: ['./solar-solutions.component.css']
})
export class SolarSolutionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
