import { HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IFeedBack } from 'src/app/shared/model/banners.model';
import { FeedbackService } from 'src/app/shared/service/feedback.service';
import Cotter from 'cotter';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  editForm: any = FormGroup;
  isAlert: boolean = false;
  submitted = false;
  success = false;
  payload = null;
  payloadString = null;
  constructor(
    private fb: FormBuilder,
    private feedbackService: FeedbackService
  ) { }

  ngOnInit(): void {
    this.editForm = this.fb.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      message: [null, [Validators.required]],

    });

  }
  save(): void {
    this.submitted = true;
    // stop here if form is invalid
    // if (this.editForm.invalid) {
    //   return;
    // }
    const contact: IFeedBack = ({
      name: this.editForm.get('name').value,
      email: this.editForm.get('email').value,
      message: this.editForm.get('message').value
    })
    this.feedbackService.sendEmail(contact).subscribe((res: HttpResponse<IFeedBack>) => {
      this.editForm.reset()
      this.submitted = false;
      this.isAlert = true

    }), (error: any) => {
      console.log(error);

    }

  }

  // convenience getter for easy access to form fields
  get f() { return this.editForm.controls; }

}
