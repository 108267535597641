import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-keybenifits',
  templateUrl: './breadcrumb-keybenifits.component.html',
  styleUrls: ['./breadcrumb-keybenifits.component.css']
})
export class BreadcrumbKKeyBenifitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
