<section class="section work-area ptb_100 reveal">
    <!-- Work Slider Wrapper -->
    <div class="work-wrapper d-none d-md-block">
        <div class="work-slider-wrapper" data-aos="zoom-in">
            <!-- Work Slider -->
            <li class="slide-item">
                <img src="assets/img/15.png" alt="">
            </li>
        </div>
    </div>
    <div class="container">
     
        <!-- Work Content -->
        <div class="row justify-content-end justify-content-lg-between work-content" id="work-slider-pager">
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Technology</h3>
                        <p>The technology sector operates under many business units such as Distribution, Corporate Solutions, Engineering Services, Power Solutions, Renewable Energy Software and Information Security.</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Marine And Watercrafts</h3>
                        <p>A pioneer in the distribution of Marine and Water-crafts from Seadoo to Evinrude and Prestige, backed by a marine engineering team and various hospitality services by the Amber collection to compliment these products.</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Digital Printing & Signage Equipment</h3>
                        <p>Debug is a provider of machinery, inks and consumables for the signage industry. The company boasts a large portfolio of BTL solutions including hoardings,.</p>
                    </div>
                </a>
            </div>
            <div class="col-12 col-sm-6">
                <a href="#" class="pager-item">
                    <!-- Single Work -->
                    <div class="single-work d-inline-block text-center p-4">
                        <h3 class="mb-2">Investments</h3>
                        <p>Debug investments has made very strategic investments into renewable energy, mining and property development.</p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</section>