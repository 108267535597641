<section class="d-none d-md-block">
  <h2 class="text-center">Other Solutions</h2>
  <div class="container">
    <div class="row">
      <!-- <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/SolarPowerSoloutions.jpg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Solar Power Soloutions</h3>
            <a href="/solar" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div> -->
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="assets/DesignConsultingELVSolut.jpg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Design & Consulting ELV Solution</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/NetworkStructuredCablingSolution.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Network and Structured Cabling Solution</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/VideoSurveillanceSystems.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Video Surveillance Systems (CCTV)</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/WI-FIZONEWITHBILLINGMANAGEMENTSYSTEM.png" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Wi-Fi Zone with Billing & Management System</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/Public-Address-Systems.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Public Address and Audio-Visual Distribution Solution</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/AccessControlSystem.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Access Control System</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/Parking-Management-System.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Parking Management System</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/Public AddressAudio-VisualDistributionSolutio.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">PBX System</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/SolarPowerSoloutions.jpg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Video Intercom System</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="assets/DesignConsultingELVSolut.jpg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Integrated Audio & Video System</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/NetworkStructuredCablingSolution.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Fire Detection & Alarm System</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/VideoSurveillanceSystems.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Intrusion Detection Solution</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/VideoSurveillanceSystems.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Building/Home Automation System</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/Public AddressAudio-VisualDistributionSolutio.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Power Backup Solution</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/Public AddressAudio-VisualDistributionSolutio.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">AIPTV & SMATV Distribution Networks solution</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/Public AddressAudio-VisualDistributionSolutio.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Lightning Protection System</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="single-blog res-margin">
          <div class="blog-thumb">
            <img src="/assets/AnnualMaintenanceContract.jpeg" alt="">
          </div>
          <div class="blog-content p-4">
            <ul class="meta-info d-flex justify-content-between">
            </ul>
            <h3 class="blog-title my-3">Annual Maintenance Contract</h3>
            <a href="#" class="blog-btn mt-3">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>