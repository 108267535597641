<section class="section download-area ptb_100 reveal">
    <!-- Shapes Container -->
    <div class="shapes-container d-none d-sm-block">
        <div class="shape-2"></div>
        <div class="shape-3"></div>
    </div>
    <div class="container ">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Download Text -->
                <div class="download-text text-center">
                    <h2>COMMITMENT</h2>
                    <p>We will never walk away from the project and the tasks you entrusted to us to fulfil, and
                        we will
                        always put forth the effort and resources to do whatever it takes to complete and
                        deliver your
                        project on time Moreover, we are dedicated to serving, satisfying and retaining existing
                        customers
                        and to win new ones through excellent performance.</p>
                </div>
            </div>
        </div>
    </div>
</section>