<section id="home" class="section welcome-area d-flex align-items-center">
    <div class="container-fluid">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-8 col-lg-6">
                <div class="welcome-intro align-items-center revealhome">
                    <h1 class="ml-4 mt-4">OBIX Private Limited</h1>
                    <h3 class="ml-4 fw-3 mt-2 mt-sm-3 font-weight-bold">Future, Try It You Will like it</h3>
                    <p class=" ml-4 my-3 text-black ">OBIX was established in 2008 aiming to develop Satellite Communication Technology, 
                        Telecommunications System, IT System Infrastructure solutions and Power and security system in Sri 
                        Lanka for domestic and commercial applications.</p>
                    <div class="button-group d-flex justify-content-center">
                        
                        <!-- <a href="#" class="btn btn-bordered"><span>Download</span></a> -->
                        <!-- <a href="#" class="btn btn-bordered d-none d-sm-inline-block mb-4">Read More</a> -->
                    </div>
                </div>
            </div>
            <a class="scroll-down" href="#about">
                <span></span>
                <span></span>
                <span></span>
            </a>
        </div>
    </div>
  
</section>