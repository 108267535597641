import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ourprojects',
  templateUrl: './ourprojects.component.html',
  styleUrls: ['./ourprojects.component.css']
})
export class OurProjectsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
