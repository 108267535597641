<header class="section header-area">
    <div id="appo-header" class="main-header-area">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <!-- Logo -->
                <a class="navbar-brand" href="">
                    <img class="logo" src="assets/img/20.png" alt="">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appo-menu">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <!-- Appo Menu -->
                <div class="collapse navbar-collapse" id="appo-menu">
                    <!-- Header Items -->
                    <ul class="navbar-nav header-items ml-auto">
                        <li class="nav-item">
                            <a class="nav-link scroll" routerLink="/about">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" href="#home">Home</a>
                        </li>
                        <li class="nav-item dropdown"> <!-- Added 'dropdown' class here -->
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Features <!-- Changed class and added dropdown attributes -->
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" href="/solar">SOLAR POWER SOLUTIONS</a>
                                <a class="dropdown-item" href="/designconsulting">Design & Consulting ELV Solution</a>
                                <a class="dropdown-item" href="#">Network and Structured Cabling Solution</a>
                                <a class="dropdown-item" href="/videosurveillancesystems">Video Surveillance Systems (CCTV)</a>
                                <a class="dropdown-item" href="/wifizonewithbilling">Wi-Fi Zone with Billing & Management System</a>
                                <a class="dropdown-item" href="/publicaddressaudiovisualdistribution">Public Address and Audio-Visual Distribution Solution </a>
                                <a class="dropdown-item" href="/accesscontrolsystem">Access Control System</a>
                                <a class="dropdown-item" href="/parkingmanagementsystem">Parking Management System </a>
                                <a class="dropdown-item" href="#">PBX System </a>
                                <a class="dropdown-item" href="/solar">Video Intercom System  </a>
                                <a class="dropdown-item" href="/designconsulting">Integrated Audio & Video System </a>
                                <a class="dropdown-item" href="#">Fire Detection & Alarm System   </a>
                                <a class="dropdown-item" href="#">Intrusion Detection Solution  </a>
                                <a class="dropdown-item" href="#">Building/Home Automation System  </a>
                                <a class="dropdown-item" href="#">Power Backup Solution </a>
                                <a class="dropdown-item" href="#">IPTV & SMATV Distribution Networks solution</a>
                                <a class="dropdown-item" href="#">Lightning Protection System </a>
                                <a class="dropdown-item" href="#">Annual Maintenance Contract </a>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll" routerLink="/projects">Projects</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link scroll"  target="_blank" href="www.demo.wikunum.lk">Shop</a>
                        </li>
               
                        <li class="nav-item">
                            <a class="nav-link scroll" href="#contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>