<div class="blog">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>
        <app-breadcrumb-blog-two-column></app-breadcrumb-blog-two-column>
        <section id="blog" class="section blog-area ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img src="assets/img/tool-fier-optics-splicing-machine.jpeg" width="300" height="300"
                            alt="Fiber Optics Splicing Machine" />
                        <label for="fef">Fiber Optics Splicing Machine</label>
                    </div>
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img src="assets/img/cable-detectors.jpeg" width="300" height="300" alt="Cable Detectors" />
                        <label for="fef">Cable Detectors</label>
                    </div>
                    
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img fluid src="assets/img/tool-rj-crimping.webp" width="300" height="300"
                            alt="RJ Crimping Tool" />
                        <label for="fef">RJ Crimping Tool</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img src="assets/img/tool-Electrical Tool Ki.jpeg" width="300" height="300"
                            alt="Electrical Tool Kit" />
                        <label for="fef">Electrical Tool Kit</label>
                    </div>
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img src="assets/img/tool-drill-machine.jpg" width="300" height="300" alt="Drill Machines" />
                        <label for="fef">Drill Machines</label>
                    </div> 
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img src="assets/img/tool-cable-teaser.jpeg" width="300" height="300" alt="Cable Tester" />
                        <label for="fef">Cable Tester</label>
                    </div>
                    
                </div>
                <div class="row">
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img src="assets/img/tool-fluke-tester.jpeg" width="300" height="300" alt="Fluke Tester" />
                        <label for="fef">Fluke Tester</label>
                    </div>
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img src="assets/img/tool-labelling-machine.png" width="300" height="300"
                            alt="Labelling Machine" />
                        <label for="fef">Labelling Machine</label>
                    </div>
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img src="assets/img/tool-gi-bender.jpeg" width="300" height="300" alt="GI Bender" />
                        <label for="fef">GI Bender</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 mb-4 mb-lg-0">
                        <img src="assets/img/tool-aluminium-laddera.png" width="300" height="300" alt="GI Bender" />
                        <label for="fef">Aluminium Ladders</label>
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </div>
</div>