import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ourmission',
  templateUrl: './ourmission.component.html',
  styleUrls: ['./ourmission.component.css']
})
export class OurMissionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
