<section id="blog" class="section blog-area ptb_100">
    <div>
        <h2 class="text-center p-4">Our Partners</h2>
      </div>
      <div class="container; d-grid gap-3">
        <h2 class="text-center" style="font-size: x-large">PRODUCT RANGE - ELV SYSTEM</h2>
        <div>
          <section id="screenshots" class="section screenshots-area ptb_80">
            <div class="container">
              <div class="row">
                <div class="col-12 reveal">
                  <div class="app-screenshots">
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/1.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/2.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/3.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/22.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/7.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/8.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/9.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/10.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/11.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/12.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/13.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/14.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/15.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/16.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/17.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/18.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/19.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/20.PNG" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="/assets/img/logo/21.PNG" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          <h2 class="text-center" style="font-size: x-large">PRODUCT RANGE - SOLAR SYSTEM</h2>
          <section id="screenshots" class="section screenshots-area ptb_80">
            <div class="container">
              <div class="row">
                <div class="col-12 reveal">
                  <!-- App Screenshot Slider Area -->
                  <div class="app-screenshots">
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/05/AESOlar.png" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/05/logo_megasol.png" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/05/smalogo.png" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/05/abb.png" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/05/omnik-1.png" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/05/solislogo.png" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/06/aleo-logo.png" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/06/growatt-logo.png" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/05/k2logo-e1591262187458.png" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/06/agile-wind-power-logo.png" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/06/cropped-leeclanche-logo.png" alt="" />
                    </div>
                    <div class="single-screenshot">
                      <img src="https://solarpower.lk/wp-content/uploads/2020/06/sunage-logo.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
</section>