<section id="screenshots" class="section screenshots-area ptb_100">
    <div class="container ">
        <h2 class="text-center mb-3">Our Products</h2>
        <div class="row">
            <div class="col-12 reveal">
                <!-- App Screenshot Slider Area -->
                <div class="products">
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/1.PNG" alt="">
                        <!-- Screenshots Overlay -->
                        <!-- <div class="screenshots-overlay">
                            <a href="/assets/img/logo/1.PNG" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div> -->
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/2.PNG" alt="">
                        <!-- Screenshots Overlay -->
                        <!-- <div class="screenshots-overlay">
                            <a href="/assets/img/logo/2.PNG" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div> -->
                    </div>
                    <!-- Single Screenshot Item -->
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/3.PNG" alt="">
                        <!-- Screenshots Overlay -->
                        <!-- <div class="screenshots-overlay">
                            <a href="/assets/img/logo/3.PNG" data-fancybox="images"><i class="icofont-image"></i></a>
                        </div> -->
                    </div>
                    <!-- <div class="single-screenshot">
                        <img src="/assets/img/logo/4.PNG" alt="">
                    </div> -->
                    <!-- <div class="single-screenshot">
                        <img src="/assets/img/logo/5.PNG" alt="">
                    </div> -->
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/22.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/7.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/8.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/9.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/10.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/11.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/12.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/13.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/14.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/15.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/16.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/17.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/18.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/19.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/20.PNG" alt="">
                    </div>
                    <div class="single-screenshot">
                        <img src="/assets/img/logo/21.PNG" alt="">
                    </div>
                </div>
            </div>
        </div>
       
    </div>
</section>
