import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ThemeOneComponent } from './themes/theme-one/theme-one.component';
import { ThemeTwoComponent } from './themes/theme-two/theme-two.component';
import { ThemeThreeComponent } from './themes/theme-three/theme-three.component';
import { ThemeFourComponent } from './themes/theme-four/theme-four.component';
import { ThemeFiveComponent } from './themes/theme-five/theme-five.component';
import { ThemeSixComponent } from './themes/theme-six/theme-six.component';
import { ThemeSevenComponent } from './themes/theme-seven/theme-seven.component';
import { ThemeEightComponent } from './themes/theme-eight/theme-eight.component';
import { ThemeNineComponent } from './themes/theme-nine/theme-nine.component';
import { LoginComponent } from './components/accounts/login/login.component';
import { SignupComponent } from './components/accounts/signup/signup.component';
import { ResetComponent } from './components/accounts/reset/reset.component';
import { AboutPageComponent } from './components/inner-pages/about-page/about-page.component';
import { PricingPageComponent } from './components/inner-pages/pricing-page/pricing-page.component';
import { ReviewsPageComponent } from './components/inner-pages/reviews-page/reviews-page.component';
import { FaqPageComponent } from './components/inner-pages/faq-page/faq-page.component';
import { DownloadPageComponent } from './components/inner-pages/download-page/download-page.component';
import { ThankYouComponent } from './components/inner-pages/thank-you/thank-you.component';
import { NewsletterPageComponent } from './components/inner-pages/newsletter-page/newsletter-page.component';
import { ErrorOneComponent } from './components/inner-pages/error-page/error-one/error-one.component';
import { ErrorTwoComponent } from './components/inner-pages/error-page/error-two/error-two.component';
import { ContactPageComponent } from './components/inner-pages/contact-page/contact-page.component';
import { MaintenanceComponent } from './components/inner-pages/maintenance/maintenance.component';
import { ComingSoonComponent } from './components/inner-pages/coming-soon/coming-soon.component';
import { BlogTwoColumnComponent } from './components/blogs/blog-two-column/blog-two-column.component';
import { BlogThreeColumnComponent } from './components/blogs/blog-three-column/blog-three-column.component';
import { BlogLeftSidebarComponent } from './components/blogs/blog-left-sidebar/blog-left-sidebar.component';
import { BlogRightSidebarComponent } from './components/blogs/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsLeftSidebarComponent } from './components/blogs/blog-details-left-sidebar/blog-details-left-sidebar.component';
import { BlogDetailsRightSidebarComponent } from './components/blogs/blog-details-right-sidebar/blog-details-right-sidebar.component';
import { BlogCommitmentComponent } from './components/blogs/blog-commitment/blog-commitment.component';
import { BlogOurTeamComponent } from './components/blogs/blog-ourtem/blog-ourteam.component';
import { BlogServicesComponent } from './components/blogs/blog-services/blog-services.component';
import { BlogToolsAndEquipmentComponent } from './components/blogs/blog-tools&equipment/blog-tools&equipment.component';
import { BlogWhyChooseUsComponent } from './components/blogs/blog-whychooseus/blog-whychooseus.component';
import { BlogkeyBenifitsComponent } from './components/blogs/blog-keybenifits/blog-keybenifits.component';
import { FeaturesComponent } from './components/features/features.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { BlogAboutComponent } from './components/blogs/blog-about/blog-about.component';
import { BlogSolarComponent } from './components/blogs/blog-solar/blog-solar.component';
import { BlogDesignConsultingComponent } from './components/blogs/blog-design-consulting/blog-design-consulting.component';
import { BlogAccessControlSystemComponent } from './components/blogs/blog-accesscontrolsystem/blog-accesscontrolsystem.component';
import { BlogParkingManagementSystemComponent } from './components/blogs/blog-parkingmanagementsystem/blog-parkingmanagementsystem.component';
import { BlogPublicAddressAudioVisualDistributionComponent } from './components/blogs/blog-publicaddressaudiovisualdistribution/blog-publicaddressaudiovisualdistribution.component';
import { BlogVideoSurveillanceSystemsComponent } from './components/blogs/blog-video surveillance systems/blog-videosurveillancesystems.component';
import { BlogWifiZoneWithBillingComponent } from './components/blogs/blog-wifizonewith billing/blog-wifizonewithbilling.component';



const routes: Routes = [
  {path: '', component: ThemeOneComponent,pathMatch:  'full'},
  {path: 'privacy-policy', component: BlogTwoColumnComponent},
  {path: 'projects', component: BlogThreeColumnComponent},
  {path: 'commitment', component: BlogCommitmentComponent},
  {path: 'ourteam', component: BlogOurTeamComponent},
  {path: 'services', component: BlogServicesComponent},
  {path: 'tools&equipment', component: BlogToolsAndEquipmentComponent},
  {path: 'whychooseus', component: BlogWhyChooseUsComponent},
  {path: 'about', component: BlogAboutComponent},
  {path: 'keybenifits', component: BlogkeyBenifitsComponent},
  {path: 'features', component: FeaturesComponent},
  {path: 'aboutus', component: AboutUsComponent},
  {path: 'solar', component: BlogSolarComponent},
  {path: 'designconsulting', component: BlogDesignConsultingComponent},
  {path: 'accesscontrolsystem', component: BlogAccessControlSystemComponent},
  {path: 'publicaddressaudiovisualdistribution', component: BlogPublicAddressAudioVisualDistributionComponent},
  {path: 'videosurveillancesystems', component: BlogVideoSurveillanceSystemsComponent},
  {path: 'wifizonewithbilling', component: BlogWifiZoneWithBillingComponent}, 
  {path: 'parkingmanagementsystem', component: BlogParkingManagementSystemComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
