<div class="blog ">
    <app-scrollup></app-scrollup>
    <div class="all-area ">
        <app-header-one></app-header-one>
        <app-breadcrumb-blog-three-column></app-breadcrumb-blog-three-column>
        <section id="blog" class="section blog-area ptb_100 ">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4">
                        <!-- Single Blog -->
                        <div class="single-blog res-margin">
                            <!-- Blog Thumb -->
                            <div class="blog-thumb">
                                <img src="/assets/img/13.jpg" alt="">
                            </div>
                            <!-- Blog Content -->
                            <div class="blog-content p-4">
                                <!-- Meta Info -->
                                <ul class="meta-info d-flex justify-content-between">
                                    <li>SAMPATH BANK – COLOMBO 02</li>
                                    <li>10.02.2016</li>
                                </ul>
                                <!-- Blog Title -->
                                <h3 class="blog-title my-3">Unifi WI- FI Solution</h3>
                                <!-- <p>Unifi Wi-Fi Access Point 542
                                    Numbers for All Branches (Supply Only)
                                    Stage 1 – 145 Wi-Fi Access Point.</p> -->
                                <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <!-- Single Blog -->
                        <div class="single-blog res-margin">
                            <!-- Blog Thumb -->
                            <div class="blog-thumb">
                                <img src="assets/img/14.jpg" alt="">
                            </div>
                            <!-- Blog Content -->
                            <div class="blog-content p-4">
                                <!-- Meta Info -->
                                <ul class="meta-info d-flex justify-content-between">
                                    <li>RANWELI HOLIDAY VILLAGE - WAIKKAL</li>
                                    <li>28.04.2016</li>
                                </ul>
                                <!-- Blog Title -->
                                <h3 class="blog-title my-3">WI- FI Solution</h3>
                                <!-- <p>Unifi Wi-Fi Solution with
                                    Billing System .</p> -->
                                <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <!-- Single Blog -->
                        <div class="single-blog">
                            <!-- Blog Thumb -->
                            <div class="blog-thumb">
                                <img src="assets/img/blog_3.jpg" alt="">
                            </div>
                            <!-- Blog Content -->
                            <div class="blog-content p-4">
                                <!-- Meta Info -->
                                <ul class="meta-info d-flex justify-content-between">
                                    <li>HIRDARAMANI – COLOMBO 02</li>
                                    <li>27.12.2015</li>
                                </ul>
                                <!-- Blog Title -->
                                <h3 class="blog-title my-3">NEC Telephone System</h3>
                                <!-- <p>NEC Telephone System NEC
                                    SL1000 System with 59 Extension.</p> -->
                                <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <!-- Single Blog -->
                        <div class="single-blog res-margin">
                            <!-- Blog Thumb -->
                            <div class="blog-thumb">
                                <img src="assets/img/blog_4.jpg" alt="">
                            </div>
                            <!-- Blog Content -->
                            <div class="blog-content p-4">
                                <!-- Meta Info -->
                                <ul class="meta-info d-flex justify-content-between">
                                    <li>MONARCH RESIDENCE – COLOMBO 03
                                    </li>
                                    <li>24.03.2016</li>
                                </ul>
                                <!-- Blog Title -->
                                <h3 class="blog-title my-3">CPPLUS IP Camera System</h3>
                                <!-- <p>Project Details -: CPPLUS 2MP Full HD 40 IP
                                    Camera with 2 NVR .</p> -->
                                <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <!-- Single Blog -->
                        <div class="single-blog res-margin">
                            <!-- Blog Thumb -->
                            <div class="blog-thumb">
                                <img src="assets/img/blog_5.jpg" alt="">
                            </div>
                            <!-- Blog Content -->
                            <div class="blog-content p-4">
                                <!-- Meta Info -->
                                <ul class="meta-info d-flex justify-content-between">
                                    <li>TRILIUM APARTMENT – COLOMBO 08
                                    </li>
                                    <li> 22.10.2015
                                    </li>
                                </ul>
                                <!-- Blog Title -->
                                <h3 class="blog-title my-3">GST Fire Alarm System</h3>
                                <!-- <p>GST Smoke Detector 126 &
                                    GST LPG Detector – 196 </p> -->
                                <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <!-- Single Blog -->
                        <div class="single-blog">
                            <!-- Blog Thumb -->
                            <div class="blog-thumb">
                                <img src="assets/img/blog_6.jpg" alt="">
                            </div>
                            <!-- Blog Content -->
                            <div class="blog-content p-4">
                                <!-- Meta Info -->
                                <ul class="meta-info d-flex justify-content-between">
                                    <li>TAJ GATEWAY - ZEEDUWA</li>
                                    <li>30.08.2015</li>
                                </ul>
                                <!-- Blog Title -->
                                <h3 class="blog-title my-3">TELEVES MATV System</h3>
                                <!-- <p>MATV Head-End 48
                                    Channels for 120 Rooms.</p> -->
                                <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <!-- Single Blog -->
                        <div class="single-blog res-margin">
                            <!-- Blog Thumb -->
                            <div class="blog-thumb">
                                <img src="assets/img/blog_1.jpg" alt="">
                            </div>
                            <!-- Blog Content -->
                            <div class="blog-content p-4">
                                <!-- Meta Info -->
                                <ul class="meta-info d-flex justify-content-between">
                                    <li>BODYLINE - HORANA</li>
                                    <li> 25.01.2017</li>
                                </ul>
                                <!-- Blog Title -->
                                <h3 class="blog-title my-3">Dahua HDCVI Camera System</h3>
                                <p>Dahua 31 camera System
                                    with Two DVR & Automatic Backup Storage.</p>
                                <!-- <a href="#" class="blog-btn mt-3">Read More</a> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <!-- Pagination -->
                        <!-- <ul class="pagination justify-content-center">
                            <li class="disabled px-1">
                                <a href="#" aria-label="Previous">
                                    <i class="fas fa-arrow-left"></i>
                                </a>
                            </li>
                            <li class="px-1"><a href="#">1</a></li>
                            <li class="active px-1"><a href="#">2</a></li>
                            <li class="px-1"><a href="#">3</a></li>
                            <li>
                                <a href="#" aria-label="Next">
                                    <i class="fas fa-arrow-right"></i>
                                </a>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </div>
</div>