<section id="about" class="section about-app-area ptb_100 wow fadeInLeft delay-05s wow fadeInUp delay-10s">
    <div class="shapes-container">
        <div class="shape-1"></div>
    </div>
    <div class="container ">
        <div class="row align-items-center">
            <div class="col-12 col-lg-6">
                <!-- About Text -->
                <div class="about-text">
                    <!-- Headings -->
                    <div class="headings d-flex align-items-center mb-4 ">
                        <span class="text-uppercase d-none d-sm-block wow ">About</span>
                        <h2 class="text-capitalize wow">OBIX Private Limited</h2>
                    </div>
                    <div class="text-justify">
                        <p class="my-3">Established in 2013. OBIX (Pvt.) Ltd, offers the complete ELV turnkey solution
                            from the first contact to the running operation which is uncompromisingly based on
                            individual needs. </p>
                        <p class="d-none d-sm-block my-3">During past years, We have provided our services to variety of
                            industries including Hospitality, Healthcare, Real-estate, Residential complexes, Factories,
                            Villas, Resorts & etc.</p>
                        <p class="d-none d-sm-block my-3">OBIX (Pvt.) Ltd, is a leading Systems Integrator focusing on
                            the Consultancy, Design, Deployment and Maintenance of all ELV Systems based on Conventional
                            & IP network switching infrastructure. Years of hands-on experience enables our ELV
                            Consultants and System Designers to provide immediate suggestions in face to face meetings
                            with our clients leading to comprehensive turnkey solutions.</p>
                        <p class="d-none d-sm-block my-3">Our ultimate target is to provide complete integrated ELV
                            solutions with quality products and services to meet the varied requirements of customer.
                        </p>
                    </div>
                    <div style="display: flex; justify-content: end;">
                    <button class="button3" [routerLink]="['/about']">Read More</button>
                    </div>

                    <!-- Store Buttons -->
                    <!-- <div class="button-group store-buttons">
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-android-robot dsp-tc"></i>
                            <p class="dsp-tc">GET IT ON
                                <br> <span>Google Play</span></p>
                        </a> 
                        <a href="#" class="btn btn-bordered">
                            <i class="icofont icofont-brand-apple dsp-tc"></i>
                            <p class="dsp-tc">AVAILABLE ON
                                <br> <span>Apple Store</span></p>
                        </a>
                    </div> -->
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <!-- About Thumb -->
                <div class="about-thumb text-center d-none d-lg-block">
                    <img src="assets/img/2.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</section>