<div class="miami">
    <app-scrollup></app-scrollup>
    <div class="all-area">
        <app-header-one></app-header-one>
        <app-hero-one></app-hero-one>
        <!-- <app-promo-one></app-promo-one> -->
        <app-about></app-about>
        <!-- <app-work></app-work> -->
        <!-- <app-features></app-features> -->
        <app-screenshot></app-screenshot>
        <app-solar-solutions></app-solar-solutions> 
        <app-solutions></app-solutions>
        <!-- <app-pricing></app-pricing> -->
        <!-- <app-reviews></app-reviews> -->
        <!-- <app-team></app-team> -->
        <!-- <app-newsletter></app-newsletter> -->
        <!-- <app-banner></app-banner> -->
        <!-- <app-reviews></app-reviews> -->
       
        <!-- <app-ourvision></app-ourvision>
        <app-ourmission></app-ourmission> -->
        <!-- <app-blog-whychooseus></app-blog-whychooseus> -->
        <app-blog-commitment></app-blog-commitment>
        <!-- <app-blog-ourteam></app-blog-ourteam> -->
        <!-- <app-blog></app-blog> -->
        <app-contact></app-contact>
        <app-footer></app-footer>
    </div>
</div>