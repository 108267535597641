import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb--blog-publicaddressaudiovisualdistribution',
  templateUrl: './breadcrumb-blog-publicaddressaudiovisualdistribution.component.html',
  styleUrls: ['./breadcrumb-blog-publicaddressaudiovisualdistribution.component.css']
})
export class BreadcrumbpublicAddressAudioVisualDistributionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
