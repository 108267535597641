<footer class="section footer-area footer-bg">
    <!-- Footer Top -->
    <div class="footer-top ptb_100">
        <div class="container">
            <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 ">
               
                <div class="col">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Contact Us</h3>
                        <ul>
                            <li class="py-2"><a class="text-white-50" href="tel:+94771109555">(+94) 77 1109 555</a></li>
                            <li class="py-2"><a class="text-white-50">No:74/5/3, Oruwala, Athurugiriya</a></li>
                            <li class="py-2"><a class="text-white-50" href="mailto:Info@obix.lk">Info@obix.lk</a></li>
                            <!-- <li class="py-2"><a class="text-white-50" href="#">Blog</a></li>
                            <li class="py-2"><a class="text-white-50" href="#">Contact</a></li> -->
                        </ul>
                    </div>
                </div>
                
                <div class="col">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Company</h3>
                        
                        <ul>
                            <li class="py-2"><a class="text-white-50" routerLink="/ourteam">Our Team</a></li>
                        </ul>

                        <ul>
                            <li class="py-2"><a class="text-white-50" routerLink="/whychooseus">Why Choose Us?</a></li>
                        </ul>
                        <!-- <ul>
                            <li class="py-2"><a class="text-white-50" routerLink="/keybenifits">Key Benifits</a></li>
                        </ul> -->
                        
                        <ul>
                            <li class="py-2"><a class="text-white-50" routerLink="/tools&equipment">Tools & Equipment</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Product Help</h3>
                        <ul>
                            <li class="py-2"><a class="text-white-50" routerLink="/services">Services</a></li>
                        </ul>
                        <ul>
                            <li class="py-2"><a class="text-white-50" routerLink="/about">About</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Logo -->
                        <a class="navbar-brand" href="#">
                            <img class="logo" src="/assets/img/20.png" alt="">
                        </a>
                        <p class="text-white-50 mt-2 mb-3">Future, Try It You Will like It</p>
                        <!-- Social Icons -->
                        <div class="social-icons d-flex">
                            <a class="facebook" href="https://www.facebook.com/profile.php?id=100083553368783">
                                <i class="fab fa-facebook-f"></i>
                                <i class="fab fa-facebook-f"></i>
                            </a>
                            <a class="twitter" href="https://instagram.com/infoobix?igshid=YmMyMTA2M2Y=">
                                <i class="fab fa-instagram"></i>
                                <i class="fab fa-instagram"></i>
                            </a>
                            <a class="google-plus" href="https://wa.me/771109555">
                                <i class="fab fa-whatsapp"></i>
                                <i class="fab fa-whatsapp"></i>
                            </a>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div
                        class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="d-flex">
                            &copy; Copyrights 2022 AlphaDevs All rights reserved. <div
                                style="padding-left: 10px;padding-right: 10px;">|</div>
                            <div>Powered by Obix</div>
                        </div>
                        <div class="d-flex">
                            <a class="text-white-50" href="/privacy-policy">Privacy Policy </a>
                            <div style="padding-left: 10px;padding-right: 10px;">|</div>
                            <a class="text-white-50" href="">Terms </a>
                            <div style="padding-left: 10px;padding-right: 10px;">|</div>
                            <a class="text-white-50" href="">Cookies</a>
                        </div>
                        <!-- Copyright Right -->
                        <!-- <div class="copyright-right text-white-50">Made with <i class="icofont-heart-alt"></i> By <a class="text-white-50" href="#">Theme Land</a></div> -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</footer>